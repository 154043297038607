import Logo from '../../common/img/logo.png'
import s from './Schedule.module.css'
import UkrMap from '../../common/map/UKR.jpg'
import RusMap from '../../common/map/RU.jpg'

export default (props) => {

    const mainRus = [
        {text: 'Регистрация участников', time: '11:00'},
        {text: 'Конкурс Онлайн', time: '11:10'},
        {text: 'Начало мероприятия, приветствественное слово всех организаторов', time: '12:00'},
        {text: 'Евгений Романенко', time: '12:10'},
        {text: 'Юрий Мелащенко', time: '12:30'},
        {text: 'Вадим Груша', time: '12:50'},
        {text: 'Конкурс Офлайн', time: '13:10'},
        {text: 'Анжелика Грохольская', time: '13:20'},
        {text: 'Дмитрий Николаевский', time: '13:40'},
        {text: 'Георгий Галоян', time: '14:00'},
        {text: 'Ник Василич', time: '14:20'},
        {text: 'Алексей Чусов', time: '14:40'},
        {text: 'Конкурс Офлайн', time: '15:00'},
        {text: 'Кофе-брейк', time: '15:10'},
        {text: 'Игорь Порох', time: '15:40'},
        {text: 'Виктор Манин', time: '16:00'},
        {text: 'Валентин Сотов', time: '16:20'},
        {text: 'Конкурс Онлайн', time: '16:30'},
        {text: 'Тарас Емельяненко', time: '16:40'},
        {text: 'Сергей Степанец', time: '17:00'},
        {text: 'Алекс Судадзе', time: '17:20'},
        {text: 'Конкурс Офлайн', time: '17:40'},
        {text: 'Jayden Sage', time: '17:50'},
        {text: 'Андрей Кисель', time: '18:10'},
        {text: 'Завершающее слово организаторов', time: '18:30'}
    ]

    const mainUa = [
        {text: 'Реєстрація учасників', time: '11:00'},
        {text: 'Конкурс Онлайн', time: '11:10'},
        {text: 'Початок заходу, вітальне слово всіх організаторів', time: '12:00'},
        {text: 'Євген Романенко', time: '12:10'},
        {text: 'Юрій Мелащенко', time: '12:30'},
        {text: 'Вадим Груша', time: '12:50'},
        {text: 'Конкурс Офлайн', time: '13:10'},
        {text: 'Анжеліка Грохольська', time: '13:20'},
        {text: 'Дмитро Нiколаєвський', time: '13:40'},
        {text: 'Георгій Галоян', time: '14:00'},
        {text: 'Нік Василич', time: '14:20'},
        {text: 'Олексій Чусов', time: '14:40'},
        {text: 'Конкурс Офлайн', time: '15:00'},
        {text: 'Кава-брейк', time: '15:10'},
        {text: 'Ігор Порох', time: '15:40'},
        {text: 'Віктор Манін', time: '16:00'},
        {text: 'Валентин Сотов', time: '16:20'},
        {text: 'Конкурс Онлайн', time: '16:30'},
        {text: 'Тарас Омеляненко', time: '16:40'},
        {text: 'Сергій Степанець', time: '17:00'},
        {text: 'Алекс Судадзе', time: '17:20'},
        {text: 'Конкурс Офлайн', time: '17:40'},
        {text: 'Jayden Sage', time: '17:50'},
        {text: 'Андрій Кисіль', time: '18:10'},
        {text: 'Завершальне слово організаторів', time: '18:30'}
    ]
 
    const workshopRus = [
        {text: 'Кибербезопасность', time: '13:30'},
        {text: 'Установка кошелька', time: '14:00'},
        {text: 'Кофе-брейк', time: '15:10'},
        {text: 'Покупки/продажа криптовалюты', time: '15:40'},
        {text: 'Создание NFT', time: '16:10'},
        {text: 'Создание DAO', time: '16:40'},
        {text: 'Анализ смарт-контрактов', time: '17:10'},
        {text: 'Экскурсия по метавселенной', time: '17:40'},
    ]

    const workshopUa = [
        {text: 'Кібербезпека', time: '13:30'},
        {text: 'Встановлення гаманця', time: '14:00'},
        {text: 'Кава-брейк', time: '15:40'},
        {text: 'Купівля/продаж криптовалюти', time: '15:40'},
        {text: 'Створення NFT', time: '16:10'},
        {text: 'Створення DAO', time: '16:40'},
        {text: 'Аналіз смарт-контрактів', time: '17:10'},
        {text: 'Екскурсія по метавсесвіту', time: '17:40'},
    ]

    return(
        <div className={`${s.schedule} container`}>
            <h1 className={s.schedule__main_title}>{props.language === 'uk' ? 'Програма' : 'Программа'}</h1>
            <div className={s.schedule__block}>
                <div className={s.schedule__main}>
                    <div className={s.schedule__title}>
                        <img src={Logo} alt="" />
                        <h1>{props.language === 'uk' ? 'Велика зала' : 'Большой зал'}</h1>
                    </div>
                    <ul>{props.language === 'uk' ? mainUa.map(el => <ScheduleItem {...el} />) : mainRus.map(el => <ScheduleItem {...el} />)}</ul>
                </div>
                <div className={s.schedule__workshop}>
                    <div className={s.schedule__title}>
                        <h1>Workshop</h1>
                        <img src={Logo} alt="" />
                    </div>
                    <ul>{props.language === 'uk' ? workshopUa.map(el => <ScheduleItem map {...el} />) : workshopRus.map(el => <ScheduleItem map {...el} />)}</ul>
                    <img className={s.programm__map} src={props.language === 'uk' ? UkrMap : RusMap} alt="" />
                </div>
            </div>
            <div id='contact'></div>
        </div>
    )
}

const ScheduleItem = (props) => {
    return(
        <li>
            <span style={(props.text === 'Кофе-брейк' || props.text === 'Кава-брейк')  ? {color: '#E86820'} : {}} className={s.item__text}>{props.text}</span>
            <span className={s.item__time}>{props.time}</span>
        </li>
    )
}