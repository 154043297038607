import Exonlogo from '../../common/img/exonlogo.png'
import s from './Sponsors.module.css'
import {ReactComponent as Logo} from '../../common/logo/logo.svg'
import exon from '../../common/logo/exon.png'
import harmony from '../../common/sponsors/harmony.png'
import obmen from '../../common/sponsors/obmen.png'
import lot from '../../common/sponsors/lot.png'
import amber from '../../common/sponsors/amber.png'
import cyprus from '../../common/sponsors/cyprus.png'
import lwallet from '../../common/sponsors/lwallet.png'

export default (props) => {
    const sponsors = [
        {logo: exon, link: 'https://exoncenter.com/ru/'},
        {logo: harmony, link: 'https://www.notion.so/harmonyone/621eddbc944140109e8804287c3f6619?v=518ef90afd384210a47d669d45fab040'},
        {logo: obmen, link: 'https://obmenat24.com/?utm_source=websiteevent1902&utm_medium=logo&utm_campaign=antimeetuplvov'},
        {logo: lot, link: 'https://lot.trade/'},
        {logo: amber, link: 'http://transform.red/en/'},
        {logo: cyprus, link: 'https://nexellencecyprus.com/'},
        {logo: lwallet, link: 'https://lwallet.com.ua/'},
    ]

    return(
        <div className={`${s.sponsors} container`}>
            <span>{props.language === 'uk' ? 'СПОНСОРИ' : 'СПОНСОРЫ'}</span>
            <div className={s.sponsors__block}>
                {sponsors.map(el => <Sponsor sponsor={el} />)} 
            </div>
            <a href='https://forms.gle/6DsEj9UEAtDdDBpc6' target='_blank' className={s.sponsors__become}>Стать спонсором</a>
            <div id='media'></div>
        </div>
    )
}

const Sponsor = (props) => {
    return(
        <a target='_blank' href={props.sponsor.link} className={s.sponsor}>
            <img src={props.sponsor.logo} alt="" />
        </a>
    )
}