import s from './Difference.module.css'
import DifferenceFirst from '../../common/img/diffBg.png'
import DifferenceSecond from '../../common/img/diffBg2.png'

export default (props) => {
    return(
        <div className={`${s.difference} container`}>
            {props.language === 'uk' ? <DifferenceUa /> : <DifferenceRus />}
        </div>
    )
}

const DifferenceRus = () => {
    return(
        <>
            <span className={s.difference__title}>ЧЕМ МЫ ОТЛИЧАЕМСЯ</span>
            <div className={s.difference__blocks}>
                <div> <span className='highlight'>АнтиМитап</span>  — это место, где спикеры не выступают с докладом, а ты не слушаешь скучные тексты</div>
                <div>Большая часть выступления спикера отведена для <span className="highlight">ответов на вопросы гостей</span></div>
                <div>Ты получишь отличную возможность <span className="highlight">спросить у спикера</span> именно то, что хочется узнать </div>
                <div>Польза посещения мероприятия определяется не тем, как много ты слушаешь, а <span className="highlight">как много вопросов задаешь</span></div>
                <div>Помни, что самый глупый вопрос – <span className="highlight">это незаданный вопрос</span></div>
                <div>Получи <span className="highlight">максимум информации</span> от каждого спикера</div>
            </div>
            <div className={s.bg__first}><img src={DifferenceFirst} alt="" /></div>
            <div className={s.bg__second}><img src={DifferenceSecond} alt="" /></div>
            <div id='intro'></div>
        </>
    )
}

const DifferenceUa = () => {
    return(
        <>
            <span className={s.difference__title}>ЧИМ МИ ВIДРIЗНЯЕМСЯ</span>
            <div className={s.difference__blocks}>
                <div> <span className='highlight'>АнтиМітап</span> - це місце, де спікери не виступають з доповіддю, а ти не слухаєш нудні тексти</div>
                <div>Більшість виступу спікера відведено для <span className='highlight'>відповідей на запитання гостей</span></div>
                <div>Ти отримаєш чудову нагоду <span className='highlight'>запитати у спікера</span> саме те, що хочеться дізнатися</div>
                <div>Користь відвідування заходу визначається не тим, як багато ти слухаєш, а <span className='highlight'>як багато запитань ставиш</span></div>
                <div>Пам'ятай, що найдурніше питання – це <span className='highlight'>непоставлене питання</span></div>
                <div>Отримай <span className='highlight'>максимум інформації</span> від кожного спікера</div>
            </div>
            <div className={s.bg__first}><img src={DifferenceFirst} alt="" /></div>
            <div className={s.bg__second}><img src={DifferenceSecond} alt="" /></div>
            <div id='intro'></div>
        </>
    )
}