import manin from '../../common/speakers/manin.jpg'
import anzhelika from '../../common/speakers/anzhelika.jpg'
import hrusha from '../../common/speakers/hrusha.jpg'
import nikolaev from '../../common/speakers/nikolaev.jpg'
import cryptomc from '../../common/speakers/cryptomc.jpg'
import aleks from '../../common/speakers/aleks.jpg'
import stepanets from '../../common/speakers/stepanets.jpg'
import sotov from '../../common/speakers/sotov.jpg'
import nick from '../../common/speakers/nick.jpg'
import taras from '../../common/speakers/taras.jpg'
import andrey from '../../common/speakers/andrey.jpeg'
import ym from '../../common/speakers/ym.png'
import galoyan from '../../common/speakers/galoyan.jpg'
import poroh from '../../common/speakers/poroh.jpg'
import sudadze from '../../common/speakers/sudadze.jpg'
import sage from '../../common/speakers/sage.jpg'

import s from './Speakers.module.css'
export default (props) => {
    
    const speakersRus = [
        {img: cryptomc, name: 'Евгений Романенко', post: 'Trustee Wallet амбассадор, экономист австрийской школы, евангелист Web 3.0, лидер мнений.', link: 'cryptoemcee.com'},
        {img: anzhelika, name: 'Анжелика Грохольская', post: 'Community manager Binance Ukraine', link: 'binance.com/uk-UA'},
        {img: sage, name: 'Джейден Сейдж', post: 'Wall Street Mathematician, Angel Investor in the Blockchain industry, Speaker and Advisor to startups, universities, government,  Chief Strategy Officer for 4 upcoming metaverses', link: 'www.linkedin.com/in/jayden-sage'},
        {img: hrusha, name: 'Вадим Груша', post: 'Founder Trustee Wallet', link: 'trusteeglobal.com/ru'},
        {img: nikolaev, name: 'Дмитрий Николаевский', post: 'Частный адвокат. Член секты свидетелей адекватного регулирования крипты в Украине.', link: 'www.facebook.com/dmitry.advocat'},
        {img: galoyan, name: 'Георгий Галоян', post: 'IT and Blockchain enterpeneur. Founder of DAO.vc & URobot.ai. Entrepreneurship in Armenian Blockchain association', link: 'dao.vc'},
        {img: poroh, name: 'Игорь Порох', post: 'Независимый аналитик и трейдер. Управляющий партнёр ITG', link: 'itg-investments.com'},
        {img: sudadze, name: 'Алекс Судадзе', post: 'CCE (Cryptocurrency Certified Expert), One of the first Bitcoin enthusiast and miner in Georgia (since 2012)', link: 'http://kdr.ge'},
        {img: nick, name: 'Ник Василич', post: 'Контрибьютор Harmony protocol, идейный вдохновитель и основатель ONERuDAO', link: 'rudao.one'},
        {img: taras, name: 'Тарас Емельяненко', post: 'CTO в Jax.network', link: 'jax.network'},
        {img: stepanets, name: 'Сергей Степанец', post: 'Professional blockchain developer', link: ''},
        {img: andrey, name: 'Андрей Кисель', post: 'CEO and Co-Founder CryptoBrewMaster, Ocean Planet, Fishmaster, BeerPunks, NFT Addicted', link: 'beerpunks.online'},
        {img: ym, name: 'Юрий Мелащенко', post: 'Основатель компании SSG (Security Services Group LLC), эксперт по кибербезопасности', link: 'ssg.systems'},
        {img: sotov, name: 'Валентин Сотов', post: 'Блокчейн оптимист. NFT куратор. Арт продюсер', link: 'transform.red'},
        {img: manin, name: 'Виктор Манин', post: 'CEO Exon Center, Биткоин предприниматель', link: 'exoncenter.com/ru'},
        {img: aleks, name: 'Алексей Чусов', post: 'Founder & СЕО First NFT Agency, researcher WEB 3 and DLT technology, sommelier', link: '1nft.agency'},
    ]

    const speakersUa = [
        {img: cryptomc, name: 'Євген Романенко', post: 'Trustee Wallet амбассадор, економіст австрійської школи, евангеліст Web 3.0, лідер думок.', link: 'cryptoemcee.com'},
        {img: anzhelika, name: 'Анжеліка Грохольська', post: 'Community manager Binance Ukraine', link: 'binance.com/uk-UA'},
        {img: sage, name: 'Джейден Сейдж', post: 'Wall Street Mathematician, Angel Investor in the Blockchain industry, Speaker and Advisor to startups, universities, government,  Chief Strategy Officer for 4 upcoming metaverses', link: 'www.linkedin.com/in/jayden-sage'},
        {img: hrusha, name: 'Вадим Груша', post: 'Founder Trustee Wallet', link: 'trusteeglobal.com/ru'},
        {img: nikolaev, name: 'Дмитро Ніколаєвський', post: 'Приватний адвокат. Член секти свідків адекватного регулювання крипти в Україні.', link: 'www.facebook.com/dmitry.advocat'},
        {img: galoyan, name: 'Георгій Галоян', post: 'IT and Blockchain enterpeneur. Founder of DAO.vc & URobot.ai. Entrepreneurship in Armenian Blockchain association', link: 'dao.vc'},
        {img: poroh, name: 'Ігор Порох', post: 'Незалежний аналітик і трейдер. Управляючий партнер ITG', link: 'itg-investments.com'},
        {img: sudadze, name: 'Алекс Судадзе', post: 'CCE (Cryptocurrency Certified Expert), One of the first Bitcoin enthusiast and miner in Georgia (since 2012)', link: 'http://kdr.ge'},
        {img: nick, name: 'Нік Василич', post: 'Контрибьютор Harmony protocol, ідейний надихач і засновник ONERuDAO', link: 'rudao.one'},
        {img: taras, name: 'Тарас Ємельяненко', post: 'CTO в Jax.network', link: 'jax.network'},
        {img: stepanets, name: 'Сергій Степанець', post: 'Professional blockchain developer', link: ''},
        {img: andrey, name: 'Андрій Кисіль', post: 'CEO and Co-Founder CryptoBrewMaster, Ocean Planet, Fishmaster, BeerPunks, NFT Addicted', link: 'beerpunks.online'},
        {img: ym, name: 'Юрій Мелащенко', post: 'Засновник компанії SSG (Security Services Group LLC), експерт кібербезпеки', link: 'ssg.systems'},
        {img: sotov, name: 'Валентин Сотов', post: 'Блокчейн оптиміст. NFT куратор. Арт продюсер', link: 'transform.red'},
        {img: manin, name: 'Віктор Манін', post: 'CEO Exon Center, Біткоїн підприємець', link: 'exoncenter.com/ru'},
        {img: aleks, name: 'Олексій Чусов', post: 'Founder & СЕО First NFT Agency, researcher WEB 3 and DLT technology, sommelier', link: '1nft.agency'},
    ]


    return(
        <div className={`${s.speakers} container`}>
            <span className={s.speakers__title}>{props.language === 'uk' ? 'СПІКЕРИ' : 'СПИКЕРЫ'}</span>
            <div className={s.speakers__block}>
                <div className="">
                    <div className={s.speaker__grid}>
                        {props.language === 'uk' ? speakersUa.map(el => <SpeakerCard {...el} />) : speakersRus.map(el => <SpeakerCard {...el} />)}
                    </div>
                    <a href='https://forms.gle/f9U7TopnYx4WXCdt9' target='_blank' className={s.speakers__become}>{props.language === 'uk' ? 'Стати спікером' : 'Стать спикером'}</a>
                </div>
            </div>
            <div id="tickets"></div>
        </div>
    )
}

const SpeakerCard = (props) => {
    return(
        <div className={s.speaker__card}>
            <img src={props.img} alt="" />  
            <div className={s.speaker__info}>
                <span className={s.speaker__name}>{props.name}</span>
                <span className={s.speaker__description}>{props.post}</span>
                <a className={s.speaker__link} target='_blank' href={props.link === 'http://kdr.ge' ? 'http://kdr.ge' : `https://${props.link}`}>{props.link.replace('/ru', '').replace('/uk-UA', '').replace('http://', '').replace('www.facebook.com/', '').replace('www.linkedin.com/in/', '')}</a>
            </div>  
        </div>
    )
}