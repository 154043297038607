import Angel from '../../common/img/main.png'
import s from './Main.module.css'
import Logo from '../../common/img/anti2022.png'


export default (props) => {
    return(
        <div id='main' className={s.main}>
            <div className={`${s.main__box} container`}>
                {props.language === 'uk' ? <MainUa /> : <MainRus /> }
                <div className={s.main__img}>
                    <img src={Angel} alt="" />
                </div>
            </div>
            
        </div>
    )
}
const MainRus = () => {
    return (
        <div className={s.main__block}>
            <h1>
                <img src={Logo} alt="" />
            </h1>
            <div className={s.main__block_text}>
                Впервые во Львове <span className='highlight'>уникальное событие</span> — криптовалютный АнтиМитап. 
                Погрузитесь в мир информации, задавая вопросы. 
                Получите ответы от лучших экспертов в сфере криптовалют! 
                Здесь нет места нудным докладам. Меньше лекций — больше вопросов и ответов.
            </div>
            <div className={s.main__place}>
                <span>
                    26 февраля, 2022 <br />
                    <a href="https://grand-hotel.com.ua/" target='_blank' >Grand Hotel</a>, Львов</span>
                <a className={s.offer__btn} href='#tickets'>Купить билет</a>
            </div>
        </div>
    )
}

const MainUa = () => {
    return(
        <div className={s.main__block}>
            <h1>
                <img src={Logo} alt="" />
            </h1>
            <div className={s.main__block_text}>
                Вперше у Львові <span className='highlight'>унікальна подія</span> – криптовалютний АнтіМітап. 
                Пориньте у світ інформації, ставлячи питання. 
                Отримайте відповіді від найкращих експертів у сфері криптовалют! 
                Тут немає місця нудним доповідям. Менше лекцій – більше запитань та відповідей.    
            </div>
            <div className={s.main__place}>
                <span>
                    26 лютого, 2022 <br />
                    <a href="https://grand-hotel.com.ua/" target='_blank' >Grand Hotel</a>, Львiв</span>
                <a className={s.offer__btn} href='#tickets'>Придбати квиток</a>
            </div>
        </div>
    )
}