import s from './Footer.module.css'
import Logo from '../../common/img/logo.png'
import {ReactComponent as Telegram} from '../../common/img/icons/telegram.svg'

export default (props) => {
    return(
        <div className={s.footer}>
            <div className={`${s.footer__block} container`}>
                <div className={s.footer__copyright}>
                    <div className="">
                        <img src={Logo} alt="" />
                        <div className={s.footer__copyright_text}>
                            <span>© 2022</span>
                            <span>All rights reserved</span>
                        </div>
                    </div>
                    <div className={s.footer__social}>
                        <a target='_blank' href='https://t.me/bedaocom'><Telegram />{props.language === 'uk' ? 'Підписатися на новини' : 'Подписаться на новости'}</a>
                        <a target='_blank' href='https://t.me/bedaocom_chat'><Telegram /> {props.language === 'uk' ? 'Приєднатися до телеграм каналу' : 'Вступить в телеграмм чат'} </a>
                    </div>
                </div>
            </div>
        </div>
    )
}