import s from './Header.module.css'
import Logo from '../../common/img/logo.png'
import {ReactComponent as Close} from '../../common/img/close.svg'

export default (props) => {

    return(
        <div style={props.isMenu ? {} : {backgroundColor: '#EAD1C41A'}} className={`${s.header} `}>
            <div className={`${s.header__block} container`}>
                <a href='#main' style={{display: 'flex'}} ><img src={Logo} alt="" /></a>
                <div className={s.header__menu} onClick={() => props.setIsMenu(!props.isMenu)} >{props.isMenu 
                    ? <div className={s.menu__close}><Close /></div> 
                    : <div className={s.menu__open}>Меню</div>}</div>
            </div>
        </div>
    )
}