import { Link } from 'react-router-dom'
import { SocialLink } from '../Contact/Contact'
import Header from '../Header/Header'
import s from './Menu.module.css'

export default (props) => {
    const socials = ['telegramChat', 'telegramChannel', 'facebook']
    let base = document.createElement('div')

    return (
        <div className={`${s.menu} container`}>
            <div className={`${s.menu__content} container`}>
                <div className={s.menu__social}>
                    {socials.map(el => <SocialLink item={el} menu />)}
                </div>
                {props.language === 'uk' ? <MenuUa setIsModal={props.setIsModal} setLanguage={props.setLanguage} language={props.language} setIsMenu={props.setIsMenu} /> : <MenuRus setIsModal={props.setIsModal} setLanguage={props.setLanguage} language={props.language} setIsMenu={props.setIsMenu} />}
                
            </div>
            
        </div>)
    
}

const MenuRus = (props) => {
    return(
        <div className={s.menu__container}>
            <div className={s.menu__div}>
                <a onClick={() => props.setIsMenu(false)} href="#intro">что ты узнаешь</a>
                <a onClick={() => props.setIsMenu(false)} href="#who">для кого</a>
                <a onClick={() => props.setIsMenu(false)} href="#speakers">спикеры</a>
                <a onClick={() => props.setIsMenu(false)} href="#tickets">билеты</a>
                <a onClick={() => props.setIsMenu(false)} href="#organizers">организаторы</a>
            </div>
            <div className={s.menu__div}>
                <a onClick={() => props.setIsMenu(false)} href="#sponsors">спонсоры</a>
                <a onClick={() => props.setIsMenu(false)} href="#media">медиа</a>
                <a onClick={() => props.setIsMenu(false)} href="#programm">программа</a>
                <a onClick={() => props.setIsMenu(false)} href="#contact">связаться с нами</a>
                <a onClick={() => {
                    props.setIsMenu(false)
                    props.setIsModal(true)
                }}>о переносе</a>
                <div className={s.menu__language}>
                    <div style={props.language !== 'uk' ? {color: '#E86820'} : {}} onClick={() => props.setLanguage('ru-RU')} className="">RU</div>
                    <div style={props.language === 'uk' ? {color: '#E86820'} : {}} onClick={() => props.setLanguage('uk')} className="">UA</div>
                </div>
            </div>
        </div>
    )
}

const MenuUa = (props) => {
    return(
        <div className={s.menu__container}>
            <div className={s.menu__div}>
                <a onClick={() => props.setIsMenu(false)} href="#intro">що дізнаєшся</a>
                <a onClick={() => props.setIsMenu(false)} href="#who">для кого</a>
                <a onClick={() => props.setIsMenu(false)} href="#speakers">спікери</a>
                <a onClick={() => props.setIsMenu(false)} href="#tickets">квитки</a>
                <a onClick={() => props.setIsMenu(false)} href="#organizers">організатори</a>
            </div>
            <div className={s.menu__div}>
                <a onClick={() => props.setIsMenu(false)} href="#sponsors">спонсори</a>
                <a onClick={() => props.setIsMenu(false)} href="#media">медіа</a>
                <a onClick={() => props.setIsMenu(false)} href="#programm">програма</a>
                <a onClick={() => props.setIsMenu(false)} href="#contact">зв'язатись з нами</a>
                <a onClick={() => {
                    props.setIsMenu(false)
                    props.setIsModal(true)
                }}>перенесення</a>
                <div className={s.menu__language}>
                    <div style={props.language !== 'uk' ? {color: '#f7dfd2'} : {}} onClick={() => props.setLanguage('ru-RU')} className="">RU</div>
                    <div style={props.language === 'uk' ? {color: '#E86820'} : {}} onClick={() => props.setLanguage('uk')} className="">UA</div>
                </div>
            </div>
        </div>
    )
}