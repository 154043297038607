import Exonlogo from '../../common/img/exonlogo.png'
import s from './Organizers.module.css'
import {ReactComponent as Logo} from '../../common/logo/logo.svg'
import Cryptoceo from '../../common/logo/manin.png'
import Cryptomc from '../../common/logo/eugene.png'
import Exon from '../../common/logo/exon.png'

export default (props) => {
    const organizers = [
        {
            logo: Exon,
            link: 'https://exoncenter.com/ru/'
        }, 
        {
            logo: Cryptomc,
            link: 'https://cryptoemcee.com/'   
        },
        {
            logo: Cryptoceo,
            link: 'https://t.me/bedaocom'
        }
        ]

    return(
        <div id='organizers' className={`${s.organizers} `}>
            <div className="container">
                <span className={s.organizers__title}>{props.language === 'uk' ? 'ОРГАНІЗАТОРИ' : 'ОРГАНИЗАТОРЫ'}</span>
                <div className={s.organizers__block}>
                {organizers.map(el => <OrganaizersList org={el} />)}
                </div>
            </div>
            <div id='sponsors'></div>
        </div>
    )
}

const OrganaizersList = (props) => {
    return(
        <a href={props.org.link} target='_blank' className={s.organizer}>
            <img src={props.org.logo} alt="" />
        </a>
    )
}