import s from './Intro.module.css'

export default (props) => {
    return(
        <div className={s.intro}>
            {props.language === 'uk' ? <IntroUa /> : <IntroRus />}
            <div id='who'></div>
        </div>
    )
}

const IntroRus = () => {
    return(
        <div className={`${s.intro__box} container`}>
            <span className={s.intro__header}>ЧТО УЗНАЕШЬ</span>
            <div className={s.intro__blocks}>
                <div className={s.intro__block}>
                    <ul>
                        <li>Не понимаешь, <span className="highlight">как работает криптовалюта</span></li>
                        <li>Не знаешь, <span className="highlight">как анализировать проекты для инвестиций</span></li>
                        <li><span className="highlight">Нет навыков</span> работы с базовыми инструментами в крипте</li>
                        <li>Слова <span className="highlight">доходное фермерство</span> связано только с агросектором</li>
                    </ul>
                    <ul>
                        <li>Не знаешь, где <span className="highlight">искать полезную информацию</span></li>
                        <li>Боишься <span className="highlight">потерять</span> деньги из-за отсутствий знаний</li>
                        <li>Не знаешь <span className="highlight">с кем пообщаться на тему очередного IDO</span></li>
                    </ul>
                    <div className={s.block__header}>До</div>
                </div>
                <div className={s.intro__block}>
                    <div className={s.block__header}>После</div>
                    <div className="">
                        <ul>
                            <li>Знаешь как <span className="highlight">формировать криптовалютные портфели</span></li>
                            <li>Научишься <span className="highlight">безопасно</span> хранить активы</li>
                            <li>Узнаешь о трендовых направлениях и <span className="highlight">технологии WEB 3.0</span></li>
                            <li>Станешь <span className="highlight">DEFI предпринимателем</span></li>
                        </ul>
                    </div>
                    <div className="">
                        <ul>
                            <li>Получишь ответы на вопросы от <span className="highlight">экспертов</span></li>
                            <li>Умеешь <span className="highlight">минтить NFT</span> и <span className="highlight">пользоваться бриджами</span></li>
                            <li>Крутой <span className="highlight">нетворкинг</span> и <span className="highlight">полная книга контактов</span></li>
                            <li>Осознаешь <span className="highlight">важность кибербезопасности</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


const IntroUa = () => {
    return(
        <div className={`${s.intro__box} container`}>
            <span className={s.intro__header}>ЩО ДІЗНАЄШСЯ</span>
            <div className={s.intro__blocks}>
                <div className={s.intro__block}>
                    <ul>
                        <li>Не розумієш, <span className="highlight">як працює криптовалюта</span></li>
                        <li>Не знаєш, як <span className="highlight">аналізувати проекти для інвестицій</span></li>
                        <li><span className="highlight">Немає навичок</span> роботи з базовими інструментами у крипті</li>
                        <li>Слова <span className="highlight">прибуткове фермерство</span> пов'язане лише з агросектором</li>
                    </ul>
                    <ul>
                        <li>Не знаєш, де <span className="highlight">шукати корисну інформацію</span></li>
                        <li>Боїшся <span className="highlight">втратити</span> гроші через відсутність знань</li>
                        <li>Не знаєш <span className="highlight">з ким поспілкуватись на тему чергового IDO</span></li>
                    </ul>
                    <div className={s.block__header}>До</div>
                </div>
                <div className={s.intro__block}>
                    <div className={s.block__header}>После</div>
                    <div className="">
                        <ul>
                            <li>Знаєш як <span className="highlight">формувати криптовалютні портфелі</span></li>
                            <li>Навчишся <span className="highlight">безпечно</span> зберігати активи</li>
                            <li>Дізнаєшся про трендові напрямки та <span className="highlight">технологію WEB 3.0</span></li>
                            <li>Станеш <span className="highlight">DEFI підприємцем</span></li>
                        </ul>
                    </div>
                    <div className="">
                        <ul>
                            <li>Отримаєш відповіді на запитання від <span className="highlight">експертів</span></li>
                            <li>Вмієш <span className="highlight">створювати NFT</span> і <span className="highlight">користуватися бріджами</span></li>
                            <li>Крутий <span className="highlight">нетворкінг</span> та <span className="highlight">повна книга контактів</span></li>
                            <li>Усвідомлюєш <span className="highlight">важливість кібербезпеки</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}