import s from './Offers.module.css'

export default (props) => {
    const offers = ['ONLINE', 'IN-HOUSE', 'VIP']

    return(
        <div className={`${s.offers} container`}>
            <span className={s.title}>{props.language === 'uk' ? 'ОБРАТИ КВИТОК' : 'ВЫБРАТЬ БИЛЕТ'}</span>
            <div className={s.offers__block}>
                {offers.map((el, index) => {
                    return <div className={`${s.offer__block} ${s.offer__soldout}`}>
                            <h1 className={s.offer__title}>{el}</h1>
                            {props.language === 'uk' ? <ListUa type={el} /> : <ListRus type={el} />}
                            <div className={`${s.soldout} ${index === 1 && s.middle__soldout}`}>
                                <span>SOLD OUT</span>
                            </div>
                        </div>
                })}
            </div>
            {/* {props.language === 'uk'
                ? <div className={s.offer__sale}>Подорожчання квитків з <span className='highlight'>21.02.2022</span></div>
                : <div className={s.offer__sale}>Подорожание цен на билеты с <span className='highlight'>21.02.2022</span></div>} */}
            <div id='organizers'></div>
        </div>
    )
}

const ListRus = (props) => {
    return(
        <>
            <ul>
                <li>Доступ к конференции в режиме реального времени</li>
                <li>Доступ к чату</li>
                <li>Возможность задать вопросы спикерам</li>
                <li>Фото отчёт конференции</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Участие в нетворкинге</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Доступ к воркшопу</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Мерч от представленных проектов</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Участие в конкурсах</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Наборы от спонсоров и партнеров</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Комфортные места в первых рядах</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Отдельная VIP-стойка регистрации без очередей</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Отдельный VIP лаунж для кофе-брейка</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Приоритетное право задать вопросы спикерам</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Запись конференции</li>
            </ul>
            <p>
                <span>{props.type === 'ONLINE' ? '' : props.type === 'IN-HOUSE' ? '' : ''}</span>
            </p>
            {/* { props.type !== 'VIP' && <a target='_blank' href='https://t.me/info_meetup' >Купить билет</a>} */}
        </>
    )
}

const ListUa = (props) => {
    return(
        <>
            <ul>
                <li>Доступ до конференції в режимі реального часу</li>
                <li>Доступ до чату</li>
                <li>Можливість поставити запитання спікерам</li>
                <li>Фото звіт конференції</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Участь у нетворкінгу</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Доступ до воркшопу</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Мерч від представлених проектів</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Участь у конкурсах</li>
                <li className={props.type === 'ONLINE' && s.excluded }>Набори від спонсорів та партнерів</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Комфортні місця у перших рядах</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Окрема VIP-стійка реєстрації без черги</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Окремий VIP лаунж для кава-перерви</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Пріоритетне право поставити питання спікерам</li>
                <li className={(props.type === 'ONLINE' || props.type === 'IN-HOUSE') && s.excluded }>Запис конференції</li>
            </ul>
            <p>
                <span>{props.type === 'ONLINE' ? '' : props.type === 'IN-HOUSE' ? '' : ''}</span>
            </p>
            {/* { props.type !== 'VIP' && <a target='_blank' href='https://t.me/info_meetup' >Придбати квиток</a>} */}
        </>
    )
}