import s from './Modal.module.css'
import {ReactComponent as Close} from '../../common/img/close.svg'


export default (props) => {
    return(
        <div onClick={e => e.target.className !== s.text && props.setIsModal(false) } className={s.modal}>
            <div className={`${s.modal__block} container`}>
                <div
                    onClick={() => props.setIsModal(false)}
                    className={s.close__modal}><Close /></div>
                    <div className={s.dark}>
                        {props.language === 'uk' ? <ModalUa /> : <ModalRu />}

                    </div>
            </div>
        </div>
    )
}

const ModalUa = () => {
    return(
        <div className={s.text}>
            Дорогі учасники Антимітапу 2022 – власники квитків, спікери та партнери!
            <br /><br />
            Нас уже понад дві сотні, всі квитки розпродані. Крипта об'єднала нас, прийшовши в життя всерйоз і назавжди. Ніхто і ніщо на цьому світі вже не здатне їй і нам завадити працювати та спілкуватися.
            <br /><br />
            Війна прийшла в Україну, але ми впевнені у нашій перемозі та готові провести мітап після перемоги.
            <br /><br />
            Ми знаємо, що ви не бажаєте відмовлятися від крипто-мітапу у Львові. Організатори – теж. Дата проведення поки що невідома. Всі зобов'язання з нашого боку залишаються чинними.
            <br /><br />
            Все буде Україна! 🇺🇦
        </div>
    )
}

const ModalRu = () => {
    return(
        <div className={s.text}>
            Дорогие участники Антимитапа 2022 - владельцы билетов, спикеры и партнёры!
            <br /><br />
            Нас уже более двух сотен, все билеты распроданы. Крипта объединила нас, прийдя в жизнь всерьез и навсегда. Никто и ничто в этом мире уже не способно ей и нам помешать работать и общаться.
            <br /><br />
            Война пришла в Украину, но мы уверены в нашей победе и готовы провести митап после победы.
            <br /><br />
            Мы знаем, что вы не хотите отказываться от крипто-митапа во Львове. Организаторы - тоже. Дата проведения пока неизвестна. Все обязательства с нашей стороны остаются в силе. 
            <br /><br />
            Все будет Украина! 🇺🇦
        </div>
    )
}