import './App.css';
import Contact from './components/Contact/Contact';
import Difference from './components/Difference/Difference';
import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import Main from './components/Main/Main';
import Offers from './components/Offers/Offers';
import Organizers from './components/Organizers/Organizers';
import Speakers from './components/Speakers/Speakers';
import Media from './components//Media/Media'
import Sponsors from './components/Sponsors/Sponsors';
import Who from './components/Who/Who';
import { useEffect, useState } from 'react';
import Menu from './components/Menu/Menu';
import { AppContext } from './Context/AppContext';
import Footer from './components/Footer/Footer';
import Background from './components/Background/Background';
import Schedule from './components/Schedule/Schedule';
import Modal from './components/Modal/Modal';

function App() {
  const [isMenu, setIsMenu] = useState(false)
  const [language, setLanguage] = useState('uk')
  const [isModal, setIsModal] = useState(true)
  // const [language, setLanguage] = useState('uk')

  return (
    // <AppContext.Provider value={{isMenu, setIsMenu}} >
      <div className="App">
        <Header isMenu={isMenu} setIsMenu={setIsMenu} />
        <div className='app__content'>
          <Main language={language} />
          <Difference language={language} />
          <Intro language={language} />
          <Who language={language} />
          <Speakers language={language} />
          <Offers language={language} />
          <Organizers language={language} />
          <Sponsors language={language} />
          <Media language={language} />
          <Schedule language={language} />
          <Contact language={language} />
          <Footer language={language} />
          { isModal && <Modal setIsModal={setIsModal} language={language} /> }
        </div>
        <Background />
        {isMenu && <Menu language={language} setIsModal={setIsModal} setLanguage={setLanguage} setIsMenu={setIsMenu} />}
      </div>
    // </AppContext.Provider>
  );
}

export default App;
