import s from './Background.module.css'
import {ReactComponent as Circle} from '../../common/img/bg/circle.svg'


export default () => {
    return(
        <div className={s.background}>
            <div className={s.background__layer}>
                <Circle className={s.circle1} />
                <Circle className={s.circle2} />
                <Circle className={s.circle3} />
                <Circle className={s.circle4} />
            </div>
        </div>
    )
}