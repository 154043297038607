import s from './Contact.module.css'
import {ReactComponent as Instagram} from '../../common/img/icons/instagram.svg'
import {ReactComponent as Facebook} from '../../common/img/icons/facebook.svg'
import {ReactComponent as Twitter} from '../../common/img/icons/twitter.svg'
import {ReactComponent as Telegram} from '../../common/img/icons/telegram.svg'
import {ReactComponent as Youtube} from '../../common/img/icons/youtube.svg'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

export default (props) => {
    const form = useRef()
    const [response, setResponse] = useState(null)
    const areaRef = useRef(null)
    const [rowCount, setRowCount] = useState(1)
    const [message, setMessage] = useState('')

    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_uz0411t', 'template_wtezqa8', form.current, 'user_eq4J09mszzZXKaJISP3Dg')
            .then((response) => {
                    setResponse('success')
                    form.current[0].value = ''
                    form.current[1].value = ''
                    form.current[2].value = ''
                }, (error) => {
                    setResponse('error')
                }
            )
    }

    useEffect(() => {
        if(response){
            setTimeout(() => {
                setResponse(null)
            }, 5000)
        }
    }, [response])

    useEffect(() => {
        if(Math.ceil(message.length / 25) > 1 ){
            setRowCount(1 + Math.ceil(message.length / 25))
        }else {
            setRowCount(1)
        }
    }, [message])

    return(
        <div id='contact' className={`${s.contact} container`}>
            <h1>{props.language === 'uk' ? 'ЗВʼЯЗАТИСЬ З НАМИ' : 'СВЯЗАТЬСЯ С НАМИ'}</h1>
            <form ref={form} className={s.contact__form} onSubmit={sendEmail}>
                <input name='user_name' placeholder={props.language === 'uk' ? 'Введіть ваше імʼя' : 'Введите ваше имя'} type="text" />
                <input name='user_email' placeholder={props.language === 'uk' ? 'Введіть ваш email' : 'Введите ваш email'} type="email" />
                <textarea value={message} onChange={e => setMessage(e.target.value)} name='user_message' placeholder={props.language === 'uk' ? 'Введіть повідомлення' : 'Введите сообщение'} rows={rowCount}></textarea>
                {response && <p className={`${response === 'success' ? s.response__success : s.response__error} ${s.response} `}>{response === 'success' ? 'Success' : 'Error. Try again later' }</p>}
                <button>{props.language === 'uk' ? 'Відправити' : 'Отправить'}</button>
            </form>
        </div>
    )
}

export const SocialLink = (props) => {
    
    const setSocial = (item) => {
        switch(item){
            case 'instagram':
                return {icon: <Instagram />, link: ''}
            case 'twitter':
                return {icon: <Twitter />, link: ''}
            case 'facebook':
                return {icon: <Facebook />, link: 'https://fb.me/e/2K25r5IEV'}
            case 'youtube':
                return {icon: <Youtube />, link: ''}
            case 'telegramChat':
                return {icon: <Telegram />, link: 'https://t.me/bedaocom_chat'}
            case 'telegramChannel':
                return {icon: <Telegram />, link: 'https://t.me/bedaocom'}
            default:
                return
        }
    }

    return(
        <a href={setSocial(props.item).link} target='_blank' className={props.menu ? s.menu__link : s.social__link}>
            {setSocial(props.item).icon}
        </a>
    )
}