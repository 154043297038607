import {ReactComponent as Arrow} from '../../common/img/arrow.svg'
import s from './Who.module.css'
import {ReactComponent as Junior} from '../../common/img/who/jun.svg'
import {ReactComponent as Middle} from '../../common/img/who/mid.svg'
import {ReactComponent as Senior} from '../../common/img/who/sen.svg'
import { useEffect, useState } from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'

export default (props) => {
    const [swiper, setSwiper] = useState()
    const personTypes = ['jun', 'mid', 'sen']
    const [person, setPerson] = useState('jun')

    const currentPersonRus = (person) => {
        switch(person){
            case 'jun':
                return { img: <Junior />, text: 'НОВИЧКАМ' }
            case 'mid':
                return { img: <Middle />, text: 'ПОЛЬЗОВАТЕЛЯМ' }
            case 'sen':
                return { img: <Senior />, text: 'ПРОФИ' }
            default: return
        }
    }

    const currentPersonUa = (person) => {
        switch(person){
            case 'jun':
                return { img: <Junior />, text: 'НОВАЧКАМ' }
            case 'mid':
                return { img: <Middle />, text: 'КОРИСТУВАЧАМ' }
            case 'sen':
                return { img: <Senior />, text: 'ПРОФI' }
            default: return
        }
    }

    return(
        <div className={`${s.who} container`}>
            {props.language === 'uk' 
                ? <WhoUa swiper={swiper} setPerson={setPerson} setSwiper={setSwiper} currentPerson={currentPersonUa} person={person} personTypes={personTypes} /> 
                : <WhoRus swiper={swiper} setPerson={setPerson} setSwiper={setSwiper} currentPerson={currentPersonRus} person={person} personTypes={personTypes} />}
        </div>
    )
}

const WhoRus = (props) => {
    return(
        <>
            <span className={s.who__header}>ДЛЯ КОГО</span>
            <div className={s.who__slider}>
                <Swiper
                    loop
                    className={s.swiper}    
                    slidesPerView={1}
                    onSwiper={swiper => props.setSwiper(swiper)}
                    allowTouchMove={false}
                >
                    <SwiperSlide className={s.swiper__text}>
                        <p>Узнаете <span className='highlight'>юридические аспекты работы</span> с криптовалютой</p>
                        <p><span className='highlight'>Поучаствуете в нетворкинге</span> с китами криптоиндустрии</p>
                        <p>Живое <span className='highlight'>общение со спикерами</span></p>
                        <p>Сделаете <span className="highlight">первые шаги в криптоиндустрии</span></p>
                    </SwiperSlide>
                    <SwiperSlide className={s.swiper__text}>
                        <p>Узнаете о <span className="highlight">возможностях заработка в криптоиндустрии</span></p>
                        <p>Приобретете <span className="highlight">новые</span> полезные <span className="highlight">знакомства</span></p>
                        <p>Поймете, как и где <span className="highlight">искать информацию и направления</span> для <span className="highlight">заработка</span></p>
                        <p>Узнаете, как <span className='highlight'>инвестировать с умом</span></p>
                    </SwiperSlide>
                    <SwiperSlide className={s.swiper__text}>
                        <p>Научитесь <span className="highlight">разбираться в современных технологиях</span> (DeFi, NFT, Play-to-earn, Metaverse)</p>
                        <p><span className="highlight">Получите опыт трейдеров</span>, которые поделяться важными инсайтами</p>
                        <p>Получите <span className="highlight">знания о кибербезопасности</span></p>
                    </SwiperSlide>
                </Swiper>
            
                <div className={s.slider__controls}>
                    <p className={s.slider__count}>{props.currentPerson(props.person).img}</p>
                    <p className={s.slider__who}>{props.currentPerson(props.person).text}</p>
                    <div className={s.slider__buttons}>
                        <button onClick={() => {
                            props.setPerson(props.personTypes[props.personTypes.indexOf(props.person)-1] === undefined ? 'sen' : props.personTypes[props.personTypes.indexOf(props.person)-1])
                            props.swiper.slidePrev()
                            }}><Arrow /></button>
                        <button onClick={() => {
                            props.setPerson(props.personTypes[props.personTypes.indexOf(props.person)+1] === undefined ? 'jun' : props.personTypes[props.personTypes.indexOf(props.person)+1])
                            props.swiper.slideNext()
                            }}><Arrow style={{transform: 'rotate(180deg)'}} /></button>
                    </div>
                </div>
            </div>
            <a href='#tickets' className={s.buy__button}>Купить билет</a>
            <div id="speakers"></div>
        </>
    )
}

const WhoUa = (props) => {
    return(
        <>
            <span className={s.who__header}>ДЛЯ КОГО</span>
            <div className={s.who__slider}>
                <Swiper
                    loop
                    className={s.swiper}    
                    slidesPerView={1}
                    onSwiper={swiper => props.setSwiper(swiper)}
                    allowTouchMove={false}
                >
                    <SwiperSlide className={s.swiper__text}>
                        <p>Дізнаєтеся <span className="highlight">юридичні аспекти роботи</span> з криптовалютою</p>
                        <p><span className="highlight">Приймете участь у нетворкінгу</span> з китами криптоіндустрії</p>
                        <p>Живе <span className="highlight">спілкування зі спікерами</span></p>
                        <p>Зробите перші кроки у криптоіндустрії</p>
                    </SwiperSlide>
                    <SwiperSlide className={s.swiper__text}>
                        <p>Дізнаєтеся про <span className="highlight">можливості заробітку в криптоіндустрії</span></p>
                        <p>Отримаєте <span className="highlight">нові</span> корисні <span className="highlight">знайомства</span></p>
                        <p>Зрозумієте, як і де <span className="highlight">шукати інформацію та напрямки</span> для заробітку</p>
                        <p>Дізнаєтесь, як <span className="highlight">інвестувати з розумом</span></p>
                    </SwiperSlide>
                    <SwiperSlide className={s.swiper__text}>
                        <p>Навчитесь <span className="highlight">розбиратися в сучасних технологіях</span> (DeFi, NFT, Play-to-earn, Metaverse)</p>
                        <p><span className="highlight">Отримаєте досвід трейдерів</span>, які ділитимуться важливими інсайдами </p>
                        <p>Отримаєте <span className="highlight">знання про кібербезпеку</span></p>
                    </SwiperSlide>
                </Swiper>
            
                <div className={s.slider__controls}>
                    <p className={s.slider__count}>{props.currentPerson(props.person).img}</p>
                    <p className={s.slider__who}>{props.currentPerson(props.person).text}</p>
                    <div className={s.slider__buttons}>
                        <button onClick={() => {
                            props.setPerson(props.personTypes[props.personTypes.indexOf(props.person)-1] === undefined ? 'sen' : props.personTypes[props.personTypes.indexOf(props.person)-1])
                            props.swiper.slidePrev()
                            }}><Arrow /></button>
                        <button onClick={() => {
                            props.setPerson(props.personTypes[props.personTypes.indexOf(props.person)+1] === undefined ? 'jun' : props.personTypes[props.personTypes.indexOf(props.person)+1])
                            props.swiper.slideNext()
                            }}><Arrow style={{transform: 'rotate(180deg)'}} /></button>
                    </div>
                </div>
            </div>
            <a href='#tickets' className={s.buy__button}>Придбати квиток</a>
            <div id="speakers"></div>
        </>
    )
}
// <div className={s.who__text}>
// <p>Сделаете первые шаги в криптоиндустрии</p>
// <p>Ознакомитесь с базовыми понятиями в сфере криптовалют</p>
// <p>Узнаете о возможностях заработка в криптоиндустрии</p>
// <p>Приобретете новые полезные знакомства</p>
// <p>Поймете, как и где искать информацию и направления для заработка</p>
// <p>Узнаете, как обеспечить свою старость и будущее с помощью криптовалюты</p>
// </div> 
// <div className={s.who__text}>
// <p>Узнаете, как инвестировать с умом</p>
// <p>Научитесь разбираться в современных технологиях (DeFi, NFT)</p>
// <p>Познакомитесь с новыми активными и пассивными методами приумножения криптовалюты</p>
// <p>Поймете, как торговать без убытков с помощью психологии</p>
// <p>Узнаете новые тренды заработка на ближайшие несколько лет</p>
// <p>Получите знания о кибербезопасности</p>
// </div> 
// <div className={s.who__text}>
// <p>Узнаете юридические аспекты работы с криптовалютой</p>
// <p>Поучаствуете в нетворкинге с китами криптоиндустрии</p>
// <p>Живое общение со спикерами</p>
// </div>