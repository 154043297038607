import Exonlogo from '../../common/img/exonlogo.png'
import s from './Media.module.css'
import mf from '../../common/media/mf.png'
import around from '../../common/media/around.png'
import bits from '../../common/media/bits.png'
import embassy from '../../common/media/embassy.png'
import partnerkin from '../../common/media/partnerkin.png'
import kripto from '../../common/media/kripto.png'
import umuseinfo from '../../common/media/umuseinfo.png'
import cashkeep from '../../common/media/cashkeep.png'
import fin from '../../common/media/fin.png'
import unit from '../../common/media/unit.png'
import world from '../../common/media/world.png'
import pipe from '../../common/media/pipe.png'
import pr from '../../common/media/pr.png'
import dna from '../../common/media/dna.png'
import block from '../../common/media/block.png'
import newz from '../../common/media/newz.png'
import gde from '../../common/media/gde.png'
import shark from '../../common/media/shark.png'
import bein from '../../common/media/bein.png'
import kharkiv from '../../common/media/kharkiv.png'
import altcoin from '../../common/media/altcoin.png'
import cryptoru from '../../common/media/cryptoru.png'
import cryptonews from '../../common/media/cryptonews.png'
import baton from '../../common/media/baton1.png'
import godynnyk from '../../common/media/godynnyk.png'
import moneyfest from '../../common/media/moneyfest.png'
import wtfbit from '../../common/media/wtfbit.png'
import crypto4iki from '../../common/media/crypto4iki.png'
import aeneas from '../../common/media/aeneas.png'
import myprofit from '../../common/media/myprofit.png'
import seo from '../../common/media/seo.png'
import whocpa from '../../common/media/whocpa.png'
import budynok from '../../common/media/budynok.png'
import marketer from '../../common/media/marketer.png'
import optsale from '../../common/media/optsale.png'
import forknews from '../../common/media/forknews.png'

export default (props) => {
    const sponsors = [
        {logo: bits, link: 'https://bits.media/'},
        {logo: bein, link: 'https://ru.beincrypto.com/'},
        {logo: mf, link: 'https://money-future.org/'},
        {logo: around, link: 'https://aroundb.com/'},
        {logo: embassy, link: 'http://embassyofbitcoin.com/'},
        {logo: partnerkin, link: 'https://partnerkin.com/'},
        {logo: kripto, link: 'https://kriptovalyuta.com'},
        {logo: godynnyk, link: 'https://t.me/+yEctRFxSw2ViMDUy'},
        {logo: cashkeep, link: 'https://cashkeep.info/', invert: true},
        {logo: fin, link: 'https://finforum.net/'},
        {logo: unit, link: 'https://kharkiv.unit.city/'},
        {logo: world, link: 'https://worldexpo.pro/'},
        {logo: pipe, link: 'https://cryptonewspipe.com/'},
        {logo: pr, link: 'https://prnews.io/ru/sites/specific-category/cryptocurrency/'},
        {logo: dna, link: 'https://dna325.com/'},
        {logo: block, link: 'https://blockchain24.pro/'},
        {logo: newz, link: 'https://www.cryptonewsz.com/'},
        {logo: gde, link: 'https://gdetraffic.com/?utm_source=p-antimeetup'},
        {logo: shark, link: 'https://thecoinshark.net/'},
        {logo: kharkiv, link: 'https://it-kharkiv.com/'},
        {logo: altcoin, link: 'https://altcoinlog.com/'},
        {logo: cryptoru, link: 'https://crypto.ru/'},
        {logo: cryptonews, link: 'https://cryptonews.one/'},
        {logo: baton, link: 'https://cpabaton.ru/'},
        {logo: umuseinfo, link: 'https://umuse.info/', invert: true},
        {logo: moneyfest, link: 'https://www.moneyfest.club/'},
        {logo: wtfbit, link: 'https://wtfbitgroup.com/'},
        {logo: crypto4iki, link: 'https://t.me/cryptochiki'},
        {logo: aeneas, link: 'http://aeneas.ua/'},
        {logo: myprofit, link: 'https://myprofit.com/ru/?utm_source=antimeetup.org&utm_medium=logo&utm_campaign=vo-lvove-v-fevrale-sostoitsja-konferencija-posvjashhennaja-blokchejn-i-kriptovaljutam'},
        {logo: seo, link: 'https://seo.ua/'},
        {logo: whocpa, link : 'http://whocpa.asia/'},
        {logo: budynok, link : 'https://budynok.com.ua/'},
        {logo: marketer, link : 'https://marketer.ua/'},
        {logo: optsale, link : 'https://asic.optsale.biz/'},
        {logo: forknews, link : 'https://forknews.io/'},
    ]
    
    return(
        <div className={`${s.media} container`}>
            <span>{props.language === 'uk' ? 'МЕДІА' : 'МЕДИА'}</span>
            <div className={s.media__block}>
                {sponsors.map(el => <MediaPartners media={el} />)} 
            </div>
            <a href='https://forms.gle/b2hKJ2vgcUKSHRGK7' target='_blank' className={s.media__become}>Стать медиа партнёром</a>
            <div id='programm'></div>
        </div>
    )
}

const MediaPartners = (props) => {
    return(
        <a href={props.media.link} target='_blank' className={s.media__partner}>
            <img style={props.media.invert ? {filter: 'invert(1)'} : null } src={props.media.logo} alt="" />
        </a>
    )
}